var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        {
          staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12",
          attrs: { topClass: "topcolor-orange" },
        },
        [
          _c("c-table", {
            ref: "table",
            attrs: {
              title: "설비 목록",
              columns: _vm.grid.columns,
              gridHeight: _vm.grid.height,
              data: _vm.grid.data,
              filtering: false,
              columnSetting: false,
              editable: false,
              usePaging: false,
              hideBottom: true,
              rowKey: "minEquipmentCheckId",
            },
            on: { rowClick: _vm.rowClick },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" },
        [
          _c("c-table", {
            ref: "table",
            attrs: {
              title: "설비별 점검항목",
              columns: _vm.grid2.columns,
              gridHeight: _vm.grid2.height,
              editable: false,
              data: _vm.result.equipmentResultList,
              merge: _vm.grid2.merge,
              filtering: false,
              columnSetting: false,
              usePaging: false,
              hideBottom: true,
              noDataLabel: "설비에 대한 점검항목이 없습니다.",
              rowKey: "checkItemSeq",
            },
          }),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }