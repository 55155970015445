<template>
  <div>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" topClass="topcolor-orange">
        <c-table
          ref="table"
          title="설비 목록"
          :columns="grid.columns"
          :gridHeight="grid.height"
          :data="grid.data"
          :filtering="false"
          :columnSetting="false"
          :editable="false"
          :usePaging="false"
          :hideBottom="true"
          rowKey="minEquipmentCheckId"
          @rowClick="rowClick"
        >
        </c-table>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <c-table
          ref="table"
          title="설비별 점검항목"
          :columns="grid2.columns"
          :gridHeight="grid2.height"
          :editable="false"
          :data="result.equipmentResultList"
          :merge="grid2.merge"
          :filtering="false"
          :columnSetting="false"
          :usePaging="false"
          :hideBottom="true"
          noDataLabel="설비에 대한 점검항목이 없습니다."
          rowKey="checkItemSeq"
        >
        </c-table>
      </div>
    </div>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'equipment-result-detail',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        title: '',
        noPlan: 'N',
        selectData: [],
      }),
    },
  },
  data() {
    return {
      editable: true,
      result: {
        minEquipmentCheckId: '',
        plantCd: '',
        plantNm: '',
        checkDeptCd: '',
        checkDeptName: '',
        checkUserId: '',
        checkUserName: '',
        mocNo: '',
        equipmentCheckKindCd: null,
        equipmentCheckKindNm: '',
        checkStatusCd: '',
        checkScheduleUserName: '',
        checkScheduleUserId: '',
        checkScheduleDate: '',
        equipmentTypeCd: '',
        equipmentCd: '',
        equipmentName: '',
        inspectionCycleName: '',
        inspectionCycleCd: '',
        recentInspectionDate: '',
        relatedLaws: '',
        regUserId: '',
        chgUserId: '',
        noPlanResultEnrollFlag: 'N',
        checkResultCd: null,
        checkCompleteFlag: null,
        checkDate: '',
        equipmentResultList: [],
      },
      grid: {
        columns: [],
        data: [],
        height: '400px'
      },
      grid2: {
        merge: [
          { index: 0, colName: "checkItemName" }],
        columns: [
          {
            name: 'checkItemName',
            field: 'checkItemName',
            label: '점검항목',
            style: 'width:200px',
            // type: 'textarea',
            align: 'left',
            sortable: false
          },
          {
            name: 'checkResult',
            field: 'checkResult',
            label: '점검결과',
            align: 'left',
            style: 'width:200px',
            sortable: false
          },
          {
            name: 'checkWayName',
            field: 'checkWayName',
            label: '검사방법',
            align: 'center',
            style: 'width:100px',
            sortable: false
          },
          {
            name: 'judgmentResultTypeCd',
            field: 'judgmentResultTypeCd',
            label: '적합/부적합',
            align: 'center',
            style: 'width:130px',
            type: 'select',
            setHeader: true,
            comboItems: [
              { code: 'MCRC000005', codeName: '적합' },
              { code: 'MCRC000010', codeName: '부적합' },
            ],
            sortable: false,
          },
          {
            name: 'decisionBasis',
            field: 'decisionBasis',
            label: '판정기준',
            // type: 'textarea',
            style: 'width:200px',
            align: 'left',
            sortable: false
          },
          {
            name: 'checkCaution',
            field: 'checkCaution',
            label: '점검시 안전사항',
            style: 'width:200px',
            align: 'left',
            sortable: false
          },

        ],
        data: [],
        height: '500px',
      },
      popupOptions: {
        target: null,
        title: "",
        visible: false,
        top: "",
        param: {},
        closeCallback: null,
      },
      chkResultItems: [],
      chkRsltYnItems: [
        { code: 'Y', codeName: '완료' },
        { code: 'N', codeName: '미완료' },
      ],
      title: '',
      disabled: false,
      insertUrl: '',
      updateUrl: '',
      getUrl: '',
      isSave: false,
      getResultItemUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      // url setting
      this.getUrl = selectConfig.sop.min.equipment.plan.get.url;
      this.getResultItemUrl = selectConfig.sop.min.equipment.result.item.get.url;
      this.updateUrl = transactionConfig.sop.min.equipment.result.updates.url;
      this.completeUrl = transactionConfig.sop.min.equipment.result.completes.url;
      // code setting
      this.$comm.getComboItems('MIM_CHECK_RESULT_CD').then(_result => {
        this.chkResultItems = _result;

        this.grid.columns = [
          {
            name: 'equipmentTypeNm',
            field: 'equipmentTypeNm',
            label: '설비유형',
            style: 'width:100px',
            align: 'center',
            sortable: false
          },
          {
            name: 'equipmentCd',
            field: 'equipmentCd',
            label: '설비코드',
            style: 'width:100px',
            align: 'center',
            sortable: false
          },
          {
            name: 'equipmentName',
            field: 'equipmentName',
            label: '설비명',
            style: 'width:120px',
            align: 'center',
            sortable: false
          },
          {
            name: 'equipmentCheckKindNm',
            field: 'equipmentCheckKindNm',
            label: '점검유형',
            align: 'center',
            style: 'width:100px',
            sortable: false,
          },
          // {
          //   name: 'checkScheduleUserName',
          //   field: 'checkScheduleUserName',
          //   label: '점검예정자',
          //   align: 'center',
          //   style: 'width:120px',
          //   sortable: false,
          // },
          // {
          //   name: 'checkScheduleDate',
          //   field: 'checkScheduleDate',
          //   label: '점검예정일',
          //   style: 'width:100px',
          //   align: 'center',
          //   sortable: false,
          // },
          {
            name: 'checkUserName',
            field: 'checkUserName',
            label: '실제점검자',
            align: 'center',
            style: 'width:120px',
            sortable: false,
          },
          {
            name: 'checkDate',
            field: 'checkDate',
            label: '실제점검일',
            style: 'width:100px',
            align: 'center',
            sortable: false,
          },
          {
            name: 'checkResultCd',
            field: 'checkResultCd',
            label: '점검결과',
            required: true,
            align: 'center',
            style: 'width:100px',
            type: 'select',
            comboItems: _result,
            sortable: false,
          },
          {
            name: 'checkCompleteFlag',
            field: 'checkCompleteFlag',
            label: '점검완료여부',
            required: true,
            align: 'center',
            style: 'width:100px',
            type: 'select',
            comboItems: [
              { code: 'Y', codeName: '완료' },
              { code: 'N', codeName: '미완료' },
            ],
            sortable: false,
          },
          {
            name: 'checkResultSummary',
            field: 'checkResultSummary',
            label: '점검결과 요약',
            style: 'width:250px',
            align: 'left',
            sortable: true
          },
        ]
      });

      // list setting
      this.title = this.popupParam.title;
      this.getList();
    },
    getList() {
      if (this.popupParam.selectData.length > 0) {
        this.$_.forEach(this.popupParam.selectData, _item => {
          this.$http.url = this.$format(this.getUrl, _item.minEquipmentCheckId);
          this.$http.type = 'GET';
          this.$http.request((_result) => {
            this.grid.data.push(_result.data)
          },);
        })
      }
    },
    rowClick(row) {
      this.result = row;
    },
    add() {
      // 초기에는 userId에 tempId를 부여
      this.popupOptions.title = '설비 검색'; // 설비 검색
      this.popupOptions.param = {
        type: 'single'
      };
      this.popupOptions.target = () => import(`${'@/pages/mdm/fim/equipmentPop.vue'}`);
      this.popupOptions.width = '60%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeEquipmentPopup;
    },
    closeEquipmentPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;


      if (data.selectData && data.selectData.length > 0) {
        this.$_.forEach(data.selectData, _item => {
            this.result.plantNm = _item.plantNm;
            this.result.deptNm = _item.deptNm,
            this.result.chkUserNm = data.userNm ? data.userNm : '',
            this.result.chkUserId = data.userId ? data.userId: '',
            this.result.safFacilityNm = null,
            this.result.safFacilityCd = null,
            this.result.equipmentType = _item.equipType,
            this.result.equipmentCd = _item.equipCd,
            this.result.equipmentNm = _item.equipNm,
            this.result.chkSchYmd = data.chkYmd ? data.chkYmd : '',
            this.result.remark = '';
        })
      }
    },
    addItem() {
      this.grid2.data.splice(0, 0, {
        checkItemSeq: 0,
        checkItemNm: '', // 점검항목
        checkResult: '', // 점검결과
        inspMethod: '', // 검사방법
        criteria: '', // 판정기준
        safetyInfo: '', // 점검시 안전사항
      })
    },
    removeItem() {
      let selectData = this.$refs['table'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '하나 이상 선택하세요.', // 하나 이상 선택하세요.
          type: 'warning', // success / info / warning / error
        });
      } else {
        this.$_.forEach(selectData, item => {
          this.grid2.data = this.$_.reject(this.grid2.data, item)
        })
      }
    },
    saveInspection() {
      let flag = false;
      this.$_.forEach(this.grid.data, _item => {
        if (!_item.checkUserId || !_item.checkDate || !_item.checkResultCd || !_item.checkCompleteFlag) {
            window.getApp.$emit('ALERT', {
            title: '안내', // 안내
            message: '필수값을 입력하세요.', // 하나 이상 선택하세요.
            type: 'warning', // success / info / warning / error
          });
          flag = true;
        }
      })
      if (!flag) {
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '저장하시겠습니까?',
          // TODO : 필요시 추가하세요.
          type: 'info', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.isSave = !this.isSave;
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    saveCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
    },
    resultComplete() {
      let count = 0;
      this.$_.forEach(this.grid.data, _item => {
        if (_item.checkCompleteFlag === 'N') {
          count++;
        }
      })
      if (count >= 1) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: count + '개의 미완료 된 설비점검이 있습니다.', // 미완료 된 설비점검입니다
          type: 'warning', // success / info / warning / error
        });
        return;
      }
      window.getApp.$emit('CONFIRM', {
        title: '확인',
        message: '점검완료 하시겠습니까?(저장과 함게 완료처리가 됩니다.)',
        // TODO : 점검완료 하시겠습니까?
        type: 'warning', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$http.url = this.completeUrl;
          this.$http.type = 'PUT';
          this.$http.param = this.grid.data;
          this.$http.request(() => {
            this.emit('SAVE');
          },);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
    });
    },
    emit(result) {
      if (result === 'COMPLETE') {
        this.$emit('closePopup', result);
      } else if (result === 'SAVE') {
        this.$emit('closePopup', result);
      }
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
    },
  }
};
</script>
